<template>
    <b-modal id="modalErrorNotRegister" ref="modalErrorNotRegister" size="md" class="modal-failed-register" centered
        hide-footer hide-header no-close-on-backdrop>
        <div class="d-block text-center">
            <!-- <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              /> -->
              <h1 class="p-4 my-2 text-dark">Anda Belum Terdaftar</h1>
            <p>Anda belum terdaftar sebagai Nasabah {{ isObligasi ? "Obligasi" : "Reksa Dana" }}, silakan lakukan
                pendaftaran pada link berikut. Atau hubungi Customer service kami via <a href="https://api.whatsapp.com/send?phone=6281511804883">WhatsApp:+62-815-1180-4883</a></p>
            <b-row class="justify-content-md-end btn-submit-row mb-4">
                <div class="col-lg-12 mt-3 col-md-6">
                    <b-button block size="sm" class="btn-tertiary px-4 py-2" to="/register">Daftar Sekarang</b-button>
                </div>
                <div class="col-lg-12 mt-2 col-md-6">
                    <b-button block size="sm" class="btn-outline-tertiary px-4 py-2"
                        @click="closeHandler">Kembali</b-button>
                </div>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'ModalNotRegister',
    props: {
        open: Boolean,
        isObligasi: Boolean,
        closeHandler: Function
    },
    methods: {
        closeCheckData3() {
            this.$refs['modalErrorNotRegister'].hide();
        }
    },
    watch: {
        open(val) {
            if (val) {
                this.$refs['modalErrorNotRegister'].show();
            } else {
                this.$refs['modalErrorNotRegister'].hide();
            }
        }
    }
}
</script>

<style lang="scss">
.btn-tertiary {
    font-family: $inter;
    background: $tertiaryColor;
    border-radius: 5px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: white;
}

.btn-tertiary:hover {
    background: $hoverTertiary;
    color: white;
}

.btn-tertiary:focus {
    background: $tertiaryColor !important;
    box-shadow: none !important;
    color: white !important;
}

.btn-tertiary:active {
    background: $hoverTertiary !important;
    outline: none !important;
    color: white !important;
}

.btn-outline-tertiary {
    font-family: $inter;
    background: white !important;
    border-radius: 4px;
    border-color: $tertiaryColor !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    border: solid 1px $tertiaryColor !important;
    color: $tertiaryColor !important;
}

.btn-outline-tertiary:hover {
    background: white;
}

.btn-outline-tertiary:focus {
    background: white !important;
    color: $tertiaryColor !important;
    border: solid 1px $tertiaryColor !important;
    box-shadow: none !important;
    border-color: $tertiaryColor !important;
}

.btn-outline-tertiary:active {
    background: white !important;
    color: $tertiaryColor !important;
    border: solid 1px $tertiaryColor !important;
    outline: none !important;
    border-color: $tertiaryColor !important;
}
</style>