<template>
    <b-modal id="modal-failed-register" ref="modalErrorPin" size="md" class="modal-failed-register" centered hide-footer
        hide-header no-close-on-backdrop no-close-on-esc>
        <div class="d-block text-center">
            <img src="@/assets/img/ekyc/failed.svg" alt="data-not-correct" />
            <h1 class="header-modal p-4 my-2 text-dark">
                Batas mencoba telah habis
            </h1>
            <p class="text-black">Silahkan Hubungi Customer Service</p>
            <b-link class="login-link my-2" href="javascript:void(0)">Hubungi disini</b-link>
            <!-- <p>{{errorMessage}}</p> -->
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'ModalPinFailed',
    props: {
        open: Boolean
    },
    watch: {
        open (val)  {
            console.log(val);
            if (val) {
                this.$refs['modalErrorPin'].show();
            } else {
                this.$refs['modalErrorPin'].hide();
            }
        }
    }
}
</script>