<template>
    <b-modal id="modalErrorMessage" v-model="open" size="md" class="modal-failed-register" centered hide-footer hide-header
        no-close-on-backdrop>
        <div class="d-block text-center">
            <h1 class="p-4 my-2 text-dark" v-if="title">{{ title }}</h1>
            <p v-if="bodyText">{{ bodyText }}</p>
            <slot v-else></slot>
            <b-row v-if="buttons.length > 0" class="justify-content-md-end btn-submit-row mb-4">
                <div v-for="(button, index) in buttons" :key="index" class="col-lg-12 mt-2 col-md-6">
                    <b-button block size="sm" class="px-4 py-2" :class="[button.solid ? 'btn-tertiary' : 'btn-outline-tertiary']"
                        @click="button.handler">{{ button.text }}</b-button>
                </div>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'ModalMessage',
    props: {
        open: Boolean,
        closeHandler: Function,
        title: String,
        bodyText: String,
        buttons: {
            type: Array,
            default () {
                return [{
                    text: 'Kembali',
                    handler: this.closeHandler
                }]
            }
        }
    },
}
</script>

<style lang="scss">
.btn-tertiary {
    font-family: $inter;
    background: $tertiaryColor;
    border-radius: 5px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: white;
}

.btn-tertiary:hover {
    background: $hoverTertiary;
    color: white;
}

.btn-tertiary:focus {
    background: $tertiaryColor !important;
    box-shadow: none !important;
    color: white !important;
}

.btn-tertiary:active {
    background: $hoverTertiary !important;
    outline: none !important;
    color: white !important;
}

.btn-outline-tertiary {
    font-family: $inter;
    background: white !important;
    border-radius: 4px;
    border-color: $tertiaryColor !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    border: solid 1px $tertiaryColor !important;
    color: $tertiaryColor !important;
}

.btn-outline-tertiary:hover {
    background: white;
}

.btn-outline-tertiary:focus {
    background: white !important;
    color: $tertiaryColor !important;
    border: solid 1px $tertiaryColor !important;
    box-shadow: none !important;
    border-color: $tertiaryColor !important;
}

.btn-outline-tertiary:active {
    background: white !important;
    color: $tertiaryColor !important;
    border: solid 1px $tertiaryColor !important;
    outline: none !important;
    border-color: $tertiaryColor !important;
}
</style>